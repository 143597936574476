import * as t from 'io-ts';
import _ from 'lodash';
import { createDynamicQuery, createParseFunction } from '@api-client/helpers/types';
import type { MetricQueryFilter } from '../client';

function getParameterizedMetric(metric: { id: string; metricId: string; targetId?: string }) {
    return (metric.targetId)
        ? `${metric.id}: value(metric:"${metric.metricId}",filters:[{id:"target_id",value:"${metric.targetId}"}])`
        : `${metric.id}: value(metric:"${metric.metricId}",filters:[])`;
}

const metricSetQueryResult = t.type({
    api: t.record(t.string, t.type({
        query: t.union([t.string, t.null]),
        result: t.record(t.string, t.number)
    }))
});

export const metricSetQuery = createDynamicQuery(
    'getMetricSet',
    (name, variables?: {
        tenantId: string;
        isoStartDate: string;
        isoEndDate: string;
        filters: MetricQueryFilter[];
        metricsByResponseGroup: Record<string, { id: string; metricId: string; targetId?: string }[]>
    }) => {
        const {
            metricsByResponseGroup,
            ...queryVariables
        } = variables!;

        console.log('metricsByResponseGroup', metricsByResponseGroup);

        return {
            query: `query ${name}($tenantId: String! $isoStartDate: String!, $isoEndDate: String!, $filters: [HqMetricFilter!]) {
                api: getTenantApi(name:$tenantId) {
                    ${_.map(metricsByResponseGroup, (metrics, responseGroup) => `${responseGroup}: metricSet(
                        responseGroup:"${responseGroup}",
                        isoStartDate:$isoStartDate,
                        isoEndDate:$isoEndDate,
                        filters:$filters
                    ) {
                        query
                        result {
                            ${metrics.map((metric) => getParameterizedMetric(metric)).join('\n')}
                        }
                    }`)}
                }
            }
            `,
            queryVariables
        };
    },
    createParseFunction(metricSetQueryResult)
);
