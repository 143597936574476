import { createDynamicQuery, createParseFunction } from '@api-client/helpers/types';
import { perfDiagsChunkSchema } from '../types';

export const perfDiagsChunkQuery = createDynamicQuery(
    'getPerfdiagChunk',
    (name, variables?: {
        tenantId: string,
        startTs: string,
        endTs: string,
        columnMetadata: {
            name: string;
            type: string;
        }[],
        columnsHash: string,
        diagnosticsId: string,
        chunkHash?: string
    }) => {
        const {
            columnMetadata,
            ...queryVariables
        } = variables!;

        const query = `
        query ${name} ($tenantId: String! $startTs: String!, $endTs: String!, $columnsHash: String!, $chunkHash: String, $diagnosticsId: String!) {
            api: getTenantApi(name: $tenantId) {
                DiagnosticsAPI {
                    perfDiagsChunk(startTs: $startTs, endTs: $endTs, columnsHash: $columnsHash, chunkHash: $chunkHash, diagnosticsId: $diagnosticsId) {
                      perfDiags {
                        ${columnMetadata.map(c => `${c.name}: get${c.type}(name: "${c.name}")`).join('\n')}
                      }
                      columnsChanged
                      chunkHash
                    }
                }
            }
        }`;

        return {
            query,
            queryVariables
        };
    },
    createParseFunction(perfDiagsChunkSchema)
);
