import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import type { FeatureCollection, MultiPolygon, Polygon } from 'geojson';
import {
    GetBoundarySetDocument,
    GetMapBearingDocument,
    GetStationTypeConfigsDocument,
} from '@/generated/graphql-operations';
import { DesGraphQLClient } from '@api-client/graphql-client';
import type { TenantApiResult } from './RootApiClient';
import { DashboardApi } from './apps/Dashboard';
import { DeploymentApi } from './apps/Deployment';
import { DiagnosticsApi } from './apps/Diagnostics';
import { HomeApi } from './apps/Home/client';
import { standardizeNulls } from './helpers';
import { DynamicQuery } from './helpers/types';

export class TenantApiClient {
    graphqlClient: DesGraphQLClient;
    api: TenantApiResult;

    homeApi: HomeApi;
    dashboardApi: DashboardApi;
    diagnosticsApi: DiagnosticsApi;
    deploymentApi: DeploymentApi;

    constructor(api: TenantApiResult, graphqlClient: DesGraphQLClient) {
        this.api = api;
        this.graphqlClient = graphqlClient;

        this.homeApi = new HomeApi(this);
        this.dashboardApi = new DashboardApi(this);
        this.diagnosticsApi = new DiagnosticsApi(this);
        this.deploymentApi = new DeploymentApi(this);
    }

    get db() {
        return this.api.db.name;
    }

    async query<ReturnType = any, Variables extends Record<string, any> = Record<string, any>>(
        query: TypedDocumentNode<ReturnType, Variables> | DynamicQuery<any, Variables, ReturnType>,
        variables?: Variables
    ): Promise<ReturnType> {
        const { data, errors } = await this.graphqlClient.request(
            query,
            variables
        );

        if (errors) {
            throw new Error(errors.map(e => e.message).join('\n'));
        }
        if (!data) {
            throw new Error('No data returned from query');
        }
        return data;
    }

    async getBoundarySet(boundarySetId: string) {
        const { boundarySet } = await this.query(GetBoundarySetDocument, { tenantId: this.db, boundarySetId }).then(r => r.api);
        return boundarySet as FeatureCollection<MultiPolygon | Polygon>;
    }

    async getStationTypeConfigs() {
        const { stationTypeConfigs } = await this.query(GetStationTypeConfigsDocument, { tenantId: this.db }).then(r => r.api);
        return standardizeNulls(stationTypeConfigs);
    }

    async getMapBearing() {
        const { mapBearing } = await this.query(
            GetMapBearingDocument,
            { tenantId: this.db },
        ).then(r => r.api);
        return standardizeNulls(mapBearing);
    }
}
